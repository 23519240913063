.bg-curve {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  stroke: none;

  transform-origin: center top;
}