
.sw-update-notice-wrapper {
  width: 100%;
  box-sizing: border-box;
  background-color: var(--passion);

  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  gap: 10px;

  @media only screen and (max-width: 420px) {
    flex-direction: column;
  }

  .sw-update-message {
    margin: 0;
    color: white;
    font-size: 14px;
    font-weight: bold;
    min-width: 50%;
  }

  .sw-update-button {
    padding: 10px;
    border-radius: 5px;
    background-color: white;
    color: var(--passion);
    font-size: 12px;
    font-weight: bold;
  }
}