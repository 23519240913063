.image-upload-component-wrapper {
  display: flex;
  align-items: center;
  gap: 15px;

  .image-section {
    width: 60%;

    display: flex;
    flex-direction: column;
    gap: 10px;
    
    .image-preview-section {
      width: 100%;

      border-radius: 10px;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .image-info-section {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .image-info-text {
      color: var(--grayFont);
      font-size: 12px;
      font-weight: bold;
    }

    .image-upload-navigation {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .image-upload-navigation-text {
        font-size: 12px;
        font-weight: bold;
      }

      .image-upload-button {
        padding: 10px;
        border-radius: 100px;
        background-color: white;

        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;

        color: var(--font);

        &.image-upload-navigation-delete {
          border: 1px solid var(--red);
          box-shadow: none;
          color: var(--red);
        }

        span {
          font-weight: bold;
          font-size: 12px;
        }
      }
    }
  }
}
