.basket {
  width: 100%;
  
  display: flex;
  flex-direction: column;
  gap: 25px;

  background-color: white;

  box-sizing: border-box;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0 0 0 / 10%);


  .basket-header-section {
    display: flex;
    align-items: center;
    gap: 20px;

    // padding: 0 10px;

    .basket-published-wrapper {
      display: flex;
      align-items: center;
      gap: 3px;

      border-radius: 100px;
      padding: 8px 10px;

      width: fit-content;

      color: white;

      cursor: default;

      &.green {
        background-color: var(--green);
      }

      &.gray {
        background-color: var(--grayFont);
      }

      .basket-published-text {
        font-size: 10px;
        font-weight: bold;
        white-space: nowrap;
      }
    }

    .basket-title-section {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .basket-title {
        display: block;
        width: fit-content;
        font-weight: bold;
        font-size: 22px;
        line-height: 28px;
      }

      .basket-description {
        margin: 0;
        font-size: 14px;
        font-weight: bold;
        color: var(--grayFont);
      }

    }

    

    
  }

  .basket-inner-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    
    .basket-control-section {
      margin-bottom: 5px;

      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;

      // border-top: 1px solid var(--lineGray);
      // border-bottom: 1px solid var(--lineGray);

      .basket-button {
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;

        padding: 10px 15px;
        // background-color: white;
        border: 2px solid var(--ink);
        // box-shadow: 0 0 10px rgba(0 0 0 / 15%);
        border-radius: 100px;

        .icon {
          font-size: 14px;
        }

        .button-name {
          font-weight: bold;
          font-size: 12px;
        }

      }

      .basket-delete {
        color: var(--red);
        border-color: var(--red);
      }

    }

    .basket-contents {
      width: 100%;
      padding: 20px 13px;
      box-sizing: border-box;
      border-radius: 15px;
      background-color: rgba(0 0 0 / 5%);
    }
  }

  

  
}