.edit-content-overlay-wrapper {
  // .forms  {
  //   display: flex;
  //   flex-direction: column;
  //   // gap: 30px;

  //   .form-section {
  //     display: flex;
  //     flex-direction: column;
  //     gap: 12px;

  //     padding: 30px 0;

  //     border-bottom: 1px dotted var(--lineGray);

  //     &:first-child {
  //       padding-top: 15px;
  //     }

  //     &.emphasis {
  //       padding: 20px;
  //       background-color: var(--paperSoft);
  //       border-radius: 20px;
  //       border: 1px dashed var(--lineGray);

  //       box-shadow: 0 0 10px rgba(0 0 0 / 10%);

  //       margin: 10px 0;
  //     }

  //     textarea {
  //       min-height: 90px;
  //       resize: none;
  //     }

  //     .form-section-title-section {
  //       display: flex;
  //       flex-direction: column;
  //       gap: 3px;

  //       .form-section-title {
  //         font-weight: bold;
  //         font-size: 14px;
  //       }

  //       .form-section-description {
  //         margin: 0;
  //         font-size: 12px;
  //         color: var(--grayFont);
  //       }

  //     }

  //     .form-section-body {
  //       display: flex;
  //       flex-direction: column;
  //       gap: 5px;

  //       .form-section-body-title {
  //         font-size: 12px;
  //         font-weight: bold;
  //         color: var(--grayFont);
  //       }
  //     }

  //   }
  // }

  .emphasis-wrapper {
    padding: 20px;
    background-color: var(--paperSoft);
    border-radius: 20px;
    border: 1px dashed var(--lineGray);

    box-shadow: 0 0 10px rgba(0 0 0 / 10%);

    margin: 10px 0;
  }

  .flex-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    * {
      cursor: pointer;
    }

    label {
      font-size: 14px;
    }
  }
}
