.slide-breadcrumb-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;

  width: 100%;

  .slide-titles {
    display: flex;
    // align-items: center;
    flex-direction: column;
    gap: 0px;

    width: 100%;

    .slide-title,
    .separator {
      color: inherit;
      display: block;
      font-size: 12px;
      opacity: 50%;

      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

      &.parent-slide {
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      &.current-slide {
        opacity: 100%;
        font-weight: bold;

        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}
