.content-card-menu-bar {
  bottom: 12px;

  display: flex;

  box-sizing: border-box;

  .button {
    cursor: pointer;

    width: 20px;
    height: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      font-size: 14px;
      color: gray;
    }
  }

  .left {
    display: flex;
    align-items: center;
    gap: 7px;

    .content-published-wrapper {
      display: flex;
      align-items: center;
      gap: 3px;

      border-left: 1px solid var(--grayFont);
      padding-left: 7px;

      &.green {
        color: var(--green);
      }

      &.gray {
        color: var(--grayFont);
      }

      .content-published-icon {
        font-size: 12px;
      }

      .content-published-text {
        font-size: 10px;
        font-weight: bold;
      }
    }
  }

  .right {
    margin-left: auto;
  }
}