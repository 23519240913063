.image-block-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .image {
    display: block;
    width: 100%;
    
    background-color: rgba(255, 255, 255, 30%);
    height: auto;
    object-fit: contain;


    max-height: 9999px;
    border-radius: 10px;

    transition: transform 0.3s ease, margin 0.3s ease;

    // antialiasing
    outline: 1px solid transparent;
    -webkit-backface-visibility: hidden;
    transform: translateZ(0);
    will-change: transform;
    -webkit-perspective: 1000;

    &.cropped {
      max-height: 30vh;
      object-fit: cover;
    }
  }

  .caption {
    font-size: 12px;
  }

  .edit-image-wrapper {
    position: relative;

    .edit-buttons {
      position: absolute;
      bottom: 10px;
      right: 10px;

      display: flex;
      align-items: center;
      gap: 15px;

      background-color: rgba(0 0 0 / 20%);
      padding: 10px;
      border-radius: 10px;

      .tilt-button {
        width: 20px;
        height: 30px;
        background-color: white;
        border-radius: 5px;
        transform: rotate(10deg);
      }

      .aspect-button {
        width: 20px;
        height: 20px;
        border: 2px dashed white;
        border-radius: 5px;

        background-color: rgba(255 255 255 / 30%);

        transition: height 0.5s ease;

        &.vertical {
          height: 30px;
        }
      }
    }
  }
}

.image-edit-contents {
  color: black;

  display: flex;
  flex-direction: column;
  gap: 10px;

  .image-wrapper {
    display: inline-block;
    width: fit-content;
    border-radius: 10px;
    overflow: hidden;

    .image {
      display: block;
      width: auto;
      max-height: 150px;
      border-radius: 10px;
      object-fit: contain;
    }
  }

  .image-url {
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 2px solid black;

    width: 100%;
  }
}
