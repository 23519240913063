.slide-edit-menu {
  position: absolute;
  top: 0;
  width: 100%;
  transform: translateY(calc(-100% - 4px));

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .slide-edit-section {
    background-color: white;
    border-radius: 100px;
    padding: 15px 20px;
    box-sizing: border-box;
    box-shadow: 0 0 20px rgba(0 0 0 / 15%);
  }

  .slide-title-edit-section {

    display: flex;
    flex-direction: column;
    gap: 5px;

    width: 100%;

    .slide-title-edit-section-header {
      font-size: 12px;
      font-weight: bold;
      color: var(--font);
    }

    .slide-title-input-wrapper {
      background-color: white;
      box-shadow: 0 0 20px rgba(0 0 0 / 15%);
      border-radius: 10px;

      .slide-title-input {
        display: block;
        
        padding: 15px 20px;
        box-sizing: border-box;
        
        font-weight: bold;
        color: var(--font);
        font-size: 16px;
      }
    }

    
  }

  .slide-edit-buttons-section {
    display: flex;
    align-items: center;
    gap: 10px;

    background-color: white;
    border-radius: 20px 20px 0 0 ;
    padding: 15px 20px;
    box-sizing: border-box;
    border: 1.5px solid var(--lineGray);
    border-bottom: none;

    color: black;

    width: 90%;

    .theme-color-section {
      display: flex;
      align-items: center;
      gap: 5px;
      overflow-x: scroll;

      padding: 10px 5px;

      .color-circle {
        min-width: 25px;
        min-height: 25px;
        border-radius: 100px;
        border: 2px solid rgb(255, 255, 255);
        box-sizing: border-box;
        box-shadow: 0 0 5px rgba(0 0 0 / 20%);

        cursor: pointer;
      }
    }

    span {
      display: block;
      font-size: 12px;
      font-weight: bold;

      white-space: nowrap;
    }

    .slide-lock-icon {
      font-size: 18px;
      margin-left: auto;
      color: gray;

      cursor: pointer;

      &.locked {
        color: #ebb112;
      }
    }

    .slide-duplicate-icon {
      font-size: 18px;

      cursor: pointer;
    }

    .slide-delete-icon {
      font-size: 18px;
      color: #e8795f;

      cursor: pointer;
    }
  }
}
