.carousel-wrapper {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  .carousel-window {
    width: 100%;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .prev-button, .next-button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;

      height: calc(100% - 20px);
      min-height: 30px;
      width: 8px;
      border-radius: 100px;
      background-color: var(--bgGrayDark);

      cursor: pointer;

      z-index: 2;

      transition: width 0.2s ease-in-out, background-color 0.2s ease-in-out;

      span {
        display: none;
        white-space: nowrap;
        font-weight: bold;
        color: white;
      }

      &:hover {
        box-sizing: border-box;
        width: 15px;
        background-color: var(--grayFont);

        span {
          display: block;
        }
      }
    }

    .carousel-items {
      width: 100%;
      max-width: 100%;
      position: relative;
  
      display: flex;
      align-items: center;
      justify-content: center;
  
      .carousel-item-wrapper {
        position: absolute;
        width: 100%;
      }
    }
  }

  

  .carousel-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;

    .carousel-dot {
      width: 7px;
      height: 7px;
      border-radius: 100px;
      background-color: var(--bgGrayDark);

      transition: background-color 0.3s ease-in-out;

      cursor: pointer;

      &.active, &:hover {
        background-color: var(--grayFont);
      }
    }
  }
}
