.users-list-section {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .users-list-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .users-list-message {
      font-size: 14px;
      font-weight: bold;
      color: var(--grayFont);
      text-align: center;
    }

    .user-list-item {
      background-color: var(--bgGray);
      border-radius: 10px;
      padding: 15px;

      .link {
        padding: 5px;
        color: var(--theme);
        border-radius: 100px;
        border: 1px solid var(--theme);
        font-size: 10px;
      }

      .user-list-item-wrapper {
        .user-list-header {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .user-list-header-top,
          .user-list-header-bottom {
            display: flex;
            align-items: center;
            gap: 10px;
          }

          font-weight: bold;

          .user-list-header-tag {
            background-color: var(--grayFont);
            padding: 5px 10px;
            border-radius: 100px;
            color: white;
            font-size: 10px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
