.single-block-content-info {
  .single-block-content-info__video {
    position: relative;

    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .icon-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      background-color: white;
      border-radius: 100px;

      box-shadow: 0 0 10px rgba(0 0 0 / 15%);

      width: 40px;
      height: 40px;

      padding: 10px;
      box-sizing: border-box;

      display: flex;
      align-items: center;
      justify-content: center;

      .video-icon {
        width: 100%;
        height: 100%;
      }
    }
  }
}
