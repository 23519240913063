.unsplash-window-component-wrapper {

  position: relative;

  display: flex;
  flex-direction: column;
  gap: 30px;

  .search-section {
    position: fixed;
    top: 130px;
    left: 50%;

    z-index: 996;

    width: 500px;
    max-width: calc(100vw - 60px);

    transform: translateX(-50%);
  }

  .images-section {

    // padding-bottom: 100px;
    margin-top: 100px;

    .no-result-message {
      display: block;
      font-size: 14px;
      color: var(--grayFont);
      font-weight: bold;
      text-align: center;
    }

    .unsplash-images {
      // display: grid;
      // grid-template-columns: repeat(2, 1fr);
      // grid-auto-flow: row dense;
      // gap: 10px;

      column-count: 2;

      .image-wrapper {
        width: 100%;
        height: fit-content;

        margin-bottom: 10px;

        border-radius: 10px;
        overflow: hidden;

        .unsplash-image {
          object-fit: contain;
          display: block;
          width: 100%;
        }
      }
    }
  }
}
