.content-header {
  position: fixed;

  top: 0;
  left: 0;

  width: 100%;

  padding: 15px 15px;

  box-sizing: border-box;

  z-index: 999;

  display: flex;
  align-items: center;
  gap: 20px;

  .chapter-button {

  }

  .content-header-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0px;

    .title-section-line {
      display: flex;
      align-items: center;
      justify-content: space-between;

      gap: 20px;

      .content-title {
        font-weight: bold;
        font-size: 14px;
        overflow: hidden;

        position: relative;
        height: 30px;
        width: 100%;

        @keyframes scroll-left {
          0% {
            // -moz-transform: translate(100%, -50%); /* Browser bug fix */
            // -webkit-transform: translate(100%, -50%); /* Browser bug fix */
            transform: translate(100%, -50%);
          }
          100% {
            // -moz-transform: translate(-100%, -50%); /* Browser bug fix */
            // -webkit-transform: translate(-100%, -50%); /* Browser bug fix */
            transform: translate(-100%, -50%);
          }
        }

        .title {
          display: block;
          white-space: nowrap;

          position: absolute;
          top: 50%;
          left: 0;

          /* Starting position */
          transform: translate(0%, -50%);

          &.scroll {
            /* Apply animation to this element */
            animation: scroll-left 15s linear infinite;
          }
        }
      }

      .right {
        display: flex;
        align-items: center;
        gap: 15px;

        .separator {
          width: 1px;
          height: 20px;
        }

        .back-button {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;

          opacity: 70%;

          cursor: pointer;

          .back-icon {
            width: 20px;
            height: 20px;
            transform: rotate(-90deg);
          }

          span {
            display: block;
            font-size: 14px;
            font-weight: bold;

            white-space: nowrap;
          }
        }
      }
    }
  }
}
