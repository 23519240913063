.brand-bar-wrapper {
  width: 100%;
  
  box-sizing: border-box;
  padding: 10px;
  
  transition: background 1s ease;

  position: absolute;
  top: 0;
  opacity: 1;

  transition: top .7s ease, opacity 1.5s ease;

  z-index: 998;

  display: flex;
  align-items: center;
  justify-content: space-between;


  background-color: rgba(255, 255, 255, 70%);
  box-shadow: 0 0 20px rgba(0 0 0 / 15%);
  border-radius: 0 0 7px 7px;

  &.hide {
    top: -100%;
    opacity: 0;
    transition: top .8s ease, opacity .8s ease;
  }

  .brand-bar {
    display: flex;
    align-items: flex-end;
    gap: 3px;

    .app-icon {
      width: 25px;
    }

    .app-logo {
      height: 14px;
    }

    span {
      display: block;
      font-weight: bold;
      color: #4abde1;
    }
  }

  .logout {
    span {
      display: block;
      font-size: 12px;
      font-weight: bold;
      color: #4e4e4e;
    }
  }

}