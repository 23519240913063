.edit-basket-overlay-wrapper {
  .emphasis-wrapper {
    padding: 20px;
    background-color: var(--paperSoft);
    border-radius: 20px;
    border: 1px dashed var(--lineGray);

    box-shadow: 0 0 10px rgba(0 0 0 / 10%);

    margin: 10px 0;
  }

  .flex-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    * {
      cursor: pointer;
    }

    label {
      font-size: 14px;
    }
  }
}