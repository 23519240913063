.baskets-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  padding-bottom: 30px;

  .baskets {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .empty-message {
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      color: var(--grayFont);
    }
  }

  .baskets-add-new-button {
    position: fixed;
    bottom: 100px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    font-size: 14px;

    padding: 10px 20px;
    border-radius: 100px;
    border: 2px solid var(--theme);

    color: var(--theme);

    background-color: white;

    .text {
      display: block;
      font-weight: bold; 
    }
  }
}
