.content-info {
  .content-thumbnail-section {
    width: 100%;
    overflow: hidden;

    &:hover {
      .content-thumbnail-image {
        transform: scale(1.05);
      }
    }

    .content-thumbnail-image {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;

      transition: transform 0.3s ease;
    }
  }

  

  .content-info-section {
    display: flex;
    flex-direction: column;
    gap: 8px;

    background-color: white;

    height: 100%;

    z-index: 1;

    span {
      display: block;
    }

    .content-title-section {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .contents-title {
        color: rgb(79, 79, 79);
        font-size: 14px;
        font-weight: bold;
      }

      .contents-description {
        color: rgba(0 0 0 / 30%);
        font-size: 10px;
        margin: 0;

        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;

        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .contents-volume {
        color: rgba(0 0 0 / 30%);
        font-size: 10px;
        font-weight: bold;
      }
    }
  }
}