.made-with-wrapper {
  width: fit-content;
  background-color: white;
  padding: 10px 15px;
  border-radius: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  span {
    font-size: 12px;
    color: var(--grayFont);
  }

  .app-icon-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    .app-icon {
      height: 20px;
    }

    .app-logo {
      height: 15px;
    }
  }
  


}