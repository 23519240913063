.public-page-screen-wrapper {
  // background: var(--paper);

  position: relative;

  width: 100vw;
  min-height: 100vh;

  padding: 50px 20px;
  padding-bottom: 80px;

  box-sizing: border-box;

  // dotted grid background
  background: rgb(248, 248, 248);
  background-image: radial-gradient(rgba(0, 0, 0, 7%) 1px, transparent 0);
  background-size: 20px 20px;
  background-position: -19px -19px;

  color: var(--font);

  display: flex;
  flex-direction: column;
  align-items: center;

  .public-page-container {
    max-width: 500px;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .made-with-section {
    width: 100%;
    margin-top: 50px;
    padding-top: 30px;
    border-top: 1.5px dashed white;
    display: flex;
    justify-content: center;
  }

}
