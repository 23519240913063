.collapsible-wrapper {

  &.padded {
    padding: 20px;
  }

  transition: padding 0.3s ease;

  &.open {
    padding: 5px;

    &.padded {
      padding: 30px 20px;
    }
    
  }

  .header-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    // padding: 0 10px;

    &.open {
      gap: 30px;

      &.bordered {
        padding-bottom: 20px;
        border-bottom: 2px dashed var(--lineGray);
      }
    }

    .header-contents {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .section-type {
        display: block;
        width: fit-content;
        font-size: 10px;
        font-weight: bold;
        border-radius: 100px;
        padding: 3px 10px;
      }

      .title {
        color: var(--ink);
        display: block;
        font-size: 22px;
        font-weight: bold;
      }

      .description {
        margin: 0;
        font-size: 12px;
        color: var(--grayFont);
      }
    }

    .icon {
      display: block;

      transform: scale(1.2);

      transition: transform 0.3s ease;

      &.closed {
        transform: rotate(90deg) scale(0.9);
      }
    }

    &:hover {
      .icon.closed {
        transform: rotate(90deg) scale(1.2);
      }
    }
  }

  .collapsible-container {
    max-height: 0;
    opacity: 0;
    margin: 0px;
    visibility: hidden;
    overflow: hidden;

    transition: none;

    &.open {
      max-height: fit-content;
      visibility: visible;
      height: fit-content;
      opacity: 1;
      margin: 0;
      margin-top: 20px;
      transition: margin 0.3s ease, opacity 1s ease;
      
    }
  }
}