.slide-cover-wrapper {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .slide-info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;

    width: 100%;

    .slide-title-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;

      span {
        display: block;
        font-weight: bold;
      }

      .parent-slide-title {
        font-size: 16px;
        opacity: 0.5;
      }

      .slide-title {
        font-size: 22px;
      }
    }
  }

  .slide-cover-guage {
    width: 50%;
    height: 3px;
    border-radius: 100px;
    overflow: hidden;

    .slide-cover-guage-fill {
      height: 100%;
    }
  }
}
