.admin-screen-wrapper-wrapper {
  width: 100vw;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 20px;
  padding-bottom: 80px;
  box-sizing: border-box;

  gap: 20px;

  color: var(--font);

  // dotted grid background
  background: rgb(248, 248, 248);
  background-image: radial-gradient(rgba(0, 0, 0, 15%) 1px, transparent 0);
  background-size: 20px 20px;
  background-position: -19px -19px;

  .admin-screen-container {
    width: 100%;
    max-width: 500px;

    padding-top: 80px;
    

    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 20px;

    .admin-header {
      display: block;
      width: 100%;
      font-size: 32px;
      font-weight: bold;

      padding-bottom: 10px;

      border-bottom: 2px dashed var(--ink);

      margin-bottom: 20px;
    }
  }
}
