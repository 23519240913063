.search-box-component-wrapper {
  width: 100%;

  .search-box-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;

    width: 100%;

    background-color: white;
    border-radius: 100px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    overflow: hidden;

    border: 2px solid var(--ink);

    padding: 10px 20px;
    padding-left: 0;
    box-sizing: border-box;

    color: var(--font);

    .search-box {
      position: relative;
      width: 100%;
      height: 100%;

      .search-box-input {
        border-radius: 0;
        background-color: white;
        width: 100%;

        padding: 10px 20px;

        font-size: 14px;
        font-weight: bold;
      }

      .delete-button-wrapper {
        position: absolute;
        top: 50%;
        right: 10px;

        transform: translateY(-50%);

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: var(--bgGrayDark);
        border-radius: 100px;

        width: 20px;
        height: 20px;

        .delete-button {
          font-size: 12px;
          color: white;
        }

      }
    }

    .search-button-wrapper {
      padding-left: 20px;
      border-left: 1px solid var(--font);

      .search-button {

      }
    }
  }
}