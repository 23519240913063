.content-welcome-wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;

  // dotted grid background
  background: rgb(246, 246, 246);
  background-image: radial-gradient(rgb(205, 205, 205) 1px, transparent 0);
  background-size: 20px 20px;
  background-position: -19px -19px;

  padding: 40px 20px;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  .content-welcome-content-wrapper {
    height: 100%;
    max-height: calc(100vh - 150px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .content-welcome-container {
      background-color: white;

      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

      border-radius: 30px;
      overflow: hidden;

      border: 7px solid white;

      // min-width: 300px;
      // max-width: 300px;
      width: 330px;

      @media screen and (min-width: 800px) {
        width: 400px;
      }

      // margin-bottom: 50px;

      box-sizing: border-box;

      display: flex;
      flex-direction: column;
      justify-content: center;

      // overflow-y: scroll;

      .welcome-card-content {
        overflow-y: scroll;
        .content-thumbnail-section {
          max-height: 50vh;
          border-radius: 0 0 10px 10px;
          overflow: hidden;

          .content-thumbnail-image {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .content-info-section {
          background-color: white;
          padding: 30px 20px;

          display: flex;
          flex-direction: column;
          gap: 15px;

          .content-title-section {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .content-title {
              display: block;
              font-size: 18px;
              font-weight: bold;

              color: var(--font);
            }

            .content-description {
              margin: 0;
              font-size: 14px;
              color: var(--grayFont);
            }
          }

          .content-volume {
            font-size: 12px;
            font-weight: bold;
            color: var(--grayFont);
          }

          .table-of-contents {
            margin-top: 10px;
            padding: 20px 15px;
            border-radius: 10px;
            background-color: var(--bgGray);

            .table-of-contents-header-section {
              display: flex;
              align-items: baseline;
              gap: 10px;

              .table-of-contents-header {
                font-weight: bold;
                font-size: 16px;
                color: var(--font);
              }

              .icon {
                margin-left: auto;
              }
            }

            .table-of-contents-items {
              display: flex;
              flex-direction: column;
              gap: 15px;

              margin-top: 20px;

              .table-of-contents-item {
                display: flex;
                align-items: center;
                gap: 15px;

                &.top-level {
                  border-top: 1px solid var(--lineGray);
                  padding-top: 20px;
                  margin-top: 10px;
                }

                .dots {
                  display: flex;
                  align-items: center;
                  gap: 5px;

                  margin-left: 10px;

                  .dot {
                    width: 5px;
                    height: 5px;
                    background-color: var(--grayFont);
                    border-radius: 100px;
                  }
                }

                .slide-index {
                  font-size: 12px;
                  font-weight: bold;
                  color: var(--font);

                  white-space: nowrap;
                }

                .page-title {
                  display: block;
                  font-weight: bold;
                  font-size: 14px;
                  color: var(--ink);

                  width: fit-content;

                  text-underline-offset: 4px;
                  line-height: 28px;

                  text-decoration: dashed underline;
                  text-decoration-color: var(--ink);
                }
              }
            }
          }
        }
      }

      .start-watching-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        border-radius: 3px;

        padding: 15px;
        background-color: black;

        outline: 5px solid white;
        box-shadow: 0 0 15px rgba(0 0 0 / 20%);

        color: white;

        cursor: pointer;

        .text {
          font-size: 16px;
          font-weight: bold;
        }

        .arrow {
          transform: rotate(90deg);
        }
      }
    }
  }

  .made-with-section {
    margin-top: auto;
  }
}
