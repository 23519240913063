.content-footer-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 15px;

  &.edit-mode {
    justify-content: center;
  }

  .separator {
    margin: 0 5px;
    width: 1px;
    height: 20px;

    opacity: 30%;
  }

  .button {
    display: flex;
    align-items: center;
    gap: 8px;

    cursor: pointer;

    &.green {
      color: var(--green);
    }

    &.chapter {
      margin-left: auto;

      &.edit-mode {
        
        padding: 8px;
        background-color: var(--bgGray);

        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;

        border: 2px dashed var(--lineGray);

        color: var(--theme);

        .button-icon {
          width: 18px;
          height: 18px;
        }
      }
    }

    .button-icon {
      width: 15px;
      height: 15px;
    }

    .button-title {
      display: block;
      font-size: 14px;
      font-weight: bold;
    }
  }
}