.recently-updated-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  padding: 5px 10px;
  color: white;

  z-index: 1;

  font-size: 10px;

  &.immediate-recent {
    background: var(--theme);
  }

  &.distant-recent {
    background: var(--paper);
    color: var(--ink);
  }

  span {
    font-weight: bold;
  }
}