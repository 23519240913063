
.admin-section-wrapper {
  width: 100%;

  display: flex;
  flex-direction: column;

  border-radius: 20px;

  padding: 30px 20px;
  box-sizing: border-box;

  background-color: var(--bgGrayDark);

  transition: background-color 0.3s ease, padding 0.3s ease,
    box-shadow 0.3s ease, border 0.3s ease;

  &.closed {
    background-color: white;
    box-shadow: 0 0 20px rgba(0 0 0 / 7%);
    padding: 20px;
  }

  .admin-section-container {
    background-color: white;
    border: 1px solid var(--lineGray);
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
