.slides-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  width: 100%;

  &.edit-mode {
    gap: 25px;
  }

  .slide-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .slide-edit-section {
      display: flex;
      align-items: center;
      gap: 10px;

      margin-left: auto;


      .slide-edit-arrow {
        padding: 10px;
        width: 12px;
        height: 12px;
        background-color: var(--bgGray);
        border-radius: 100px;

        cursor: pointer;
      }

      .down-arrow {
        transform: rotate(180deg);
      }

      .left-arrow {
        transform: rotate(-90deg);
      }

      .right-arrow {
        transform: rotate(90deg);
      }
    }

    .slide-container-wrapper {
      display: flex;
      align-items: center;
      gap: 20px;

      .slide-depth-level-section {
        display: flex;
        align-items: center;
        gap: 5px;

        .indent-dot {
          background-color: var(--grayFont);
          width: 5px;
          height: 5px;
          border-radius: 100px;
          opacity: 50%;
        }

      }

      .slide-container {
        width: 100%;
        background-color: var(--bgGray);

        padding: 15px 20px;
        box-sizing: border-box;

        border-radius: 10px;

        cursor: pointer;

        display: flex;
        align-items: center;
        gap: 20px;

        background-color: var(--bgGray);

        color: var(--font);

        &.active {
          background-color: white;
          border: 3px solid var(--ink);
          box-shadow: 0 0 10px rgba(0 0 0 / 15%);
        }

        span {
          display: block;
          font-weight: bold;
        }

        .slide-title-section {
          display: flex;
          flex-direction: column;
          gap: 5px;

          .index {
            color: var(--grayFont);
            font-size: 12px;
            white-space: nowrap;
          }

          .slide-title {
            font-size: 14px;
          }
        }

        .blocks-count {
          border-left: 1px solid var(--grayFont);
          padding-left: 15px;
          margin-left: auto;
          font-weight: normal;
          color: var(--grayFont);
          font-size: 12px;

          white-space: nowrap;
        }
      }
    }
  }
}
