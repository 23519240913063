.creator-screen-background {
  
  background: white;

  .creator-screen-sw-update-notice {
    position: sticky;
    top: 0;
    width: 100vw;
    z-index: 999;

    box-shadow: 0 0 10px rgba(0 0 0 / 15%);
  }

  .creator-screen-wrapper {
    position: relative;

    width: 100vw;
    min-height: 100vh;

    padding: 0 20px;
    padding-top: 80px;
    padding-bottom: 150px;

    box-sizing: border-box;

    
    // dotted grid background
    // background: rgb(248, 248, 248);
    // background-image: radial-gradient(rgba(0, 0, 0, 15%) 1px, transparent 0);
    // background-size: 20px 20px;
    // background-position: -19px -19px;
    

    display: flex;
    justify-content: center;

    color: var(--font);

    .creator-screen-content-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
  
      .creator-screen-container {
        width: 100%;
        max-width: 500px;

        display: flex;
        flex-direction: column;
        gap: 30px;

        .creator-screen-title-section {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 30px;

          padding: 20px;
          box-sizing: border-box;
          border: 3px solid white;

          background: var(--themeGradient);

          border-radius: 20px;
          box-shadow: 0 0 20px rgba(0 0 0 / 10%);

          color: white;

          .creator-screen-icon {
            opacity: 50%;
            font-size: 42px;
            transform: rotate(-8deg);
          }

          .creator-screen-title-texts {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .creator-screen-title {
              display: block;
              font-size: 22px;
              font-weight: bold;
            }

            .creator-screen-description {
              font-size: 12px;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
