.uploading-guage-wrapper {
  width: 100%;
  height: 30px;
  padding: 10px 15px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 100px;

  box-shadow: 0 0 20px rgba( 0 0 0 / 15%);

  display: flex;
  align-items: center;

  &.seethrough {
    opacity: 50%;
    box-shadow: none;
  }

  .message {
    display: block;
    width: fit-content;
    font-weight: bold;
    font-size: 10px;
    color: black;
  }

  .guage-back {
    height: 7px;
    width: 60%;
    margin-left: auto;
    border-radius: 100px;
    background-color: rgb(225, 225, 225);

    .guage-bar {
      border-radius: 100px;
      height: 100%;
      background-color: orange;

      transition: width .3s ease;
    }
  }
  
}