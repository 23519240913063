.overlay-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  max-width: 100%;
  background-color: rgba(0 0 0 / 30%);
  backdrop-filter: blur(3px);

  padding: 0 20px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  z-index: 999;

  overflow: hidden;
  /* Prevent scrolling behind the div */

  color: var(--font);

  // ----- some defaults ---------------
  input:not([type="checkbox"]),
  textarea {
    background-color: var(--bgGray);
    width: 100%;
    box-sizing: border-box;

    border: none;
    padding: 10px;
    border-radius: 5px;

    outline: none;
  }

  input::placeholder,
  textarea::placeholder {
    color: rgba(0 0 0 / 30%);
  }

  .container {
    padding: 0 20px;
    box-sizing: border-box;
  }

  .overlay-contents-wrapper {
    position: relative;

    // padding: 20px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0 0 0 / 20%);

    overflow: hidden;

    width: 100%;
    max-width: 500px;
    max-height: 80vh;

    .overlay-contents {
      position: relative;

      // display: flex;
      // flex-direction: column;
      // gap: 20px;

      width: 100%;
      height: 100%;

      overflow-y: scroll;

      .overlay-title-section {
        position: sticky;
        width: 100%;
        top: 0;
        z-index: 997;

        border-radius: 10px 10px 0 0;
        background-color: white;
        padding: 15px 20px;

        border-bottom: 1px dashed var(--lineGray);

        display: flex;
        flex-direction: column;
        gap: 10px;

        background-color: var(--bgGray);

        .overlay-title-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .overlay-title {
            display: block;
            font-weight: bold;
            color: var(--font);

            border-left: 5px solid var(--theme);
            padding-left: 10px;
          }

          .overlay-close {
            padding: 5px;
            width: 25px;
            height: 25px;
            box-sizing: border-box;
            border-radius: 100px;
            background-color: white;

            cursor: pointer;
          }
        }

        .overlay-description {
          margin: 0;
          font-size: 12px;
          color: var(--grayFont);

          width: 80%;
        }
      }

      .overlay-children-container {
        padding-top: 30px;
        padding-bottom: 30px;
      }

      .overlay-buttons {
        position: sticky;
        bottom: 0;

        background-color: var(--bgGray);


        display: flex;
        justify-content: flex-end;
        gap: 20px;

        padding-top: 15px;
        padding-bottom: 15px;

        border-top: 1px dashed var(--lineGray);

        z-index: 997;

        

        .overlay-button {
          padding: 10px;
          border-radius: 5px;
          cursor: pointer;

          min-width: 70px;

          display: flex;
          align-items: center;
          justify-content: center;

          span {
            display: block;
            font-weight: bold;
            font-size: 14px;
          }

          &.primary {
            // background-color: black;
            background: rgb(190, 231, 244);
            background: linear-gradient(
              163deg,
              rgba(190, 231, 244, 1) 0%,
              rgba(74, 189, 225, 1) 100%
            );

            box-shadow: 0 0 10px rgba(0 0 0 / 10%);

            span {
              color: white;
            }
          }

          &.secondary {
            span {
              color: gray;
            }
          }
        }
      }
    }
  }

  &.full-screen {
    .overlay-contents-wrapper {
      border-radius: 0;
      box-shadow: none;

      max-width: 100vw;
      max-height: 100vh;
      width: 100vw;
      height: 100vh;

      .overlay-contents {
        gap: 50px;

        .overlay-title-section {
          border-radius: 0;
        }

        .overlay-children-container {
          width: 100%;
          max-width: 500px;
          margin: 0 auto;

          padding-bottom: 150px;
        }

        .overlay-buttons {
          width: 100%;
          position: fixed;
          padding-bottom: 40px;
        }
      }
    }
  }


}
