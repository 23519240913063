.video-block-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .video {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 9999px;
    border-radius: 10px;

    position: relative;
    z-index: 998;

    &.cropped {
      max-height: 30vh;
    }
  }

  .caption {
    font-size: 12px;
  }


  .edit-video-wrapper {
    position: relative;

    .edit-aspect-ratio-section {
      position: absolute;
      z-index: 998;
      bottom: 10px;
      right: 10px;
      
      padding: 10px;
      background-color: rgba(0 0 0 / 30%);
      border-radius: 5px;

      .aspect-button {
        width: 20px;
        height: 20px;
        border: 2px dashed white;
        border-radius: 5px;
  
        background-color: rgba(255 255 255 / 30%);
  
        transition: height .5s ease;
  
        &.vertical {
          height: 30px;
        }
      }
    }

    

  }

  
}

.video-edit-contents {
  color: black;

  display: flex;
  flex-direction: column;
  gap: 10px;


  .video-wrapper {
    display: inline-block;
    width: fit-content;
    border-radius: 10px;
    overflow: hidden;

    .video {
      display: block;
      width: auto;
      max-height: 150px;
      border-radius: 10px;
      object-fit: contain;
    }
  }

  

  .video-url {
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 2px solid black;

    width: 100%;
  }
}