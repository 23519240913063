.code-block-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border-radius: 10px;

  transition: background-color 0.3s ease;

  overflow: hidden;

  .file-name {
    width: 100%;
    display: block;
    font-weight: bold;
    font-size: 14px;
    opacity: 50%;
    font-family: Inconsolata, Monaco, Consolas, "Courier New", Courier,
      monospace;
  }

  .language {
    display: block;
    font-weight: normal;
    font-size: 12px;
    opacity: 50%;

    width: fit-content;
    margin-left: auto;

    padding: 0px 10px;
  }

  .language-selector {
    max-width: 50%;
    margin-left: auto;

    * {
      font-size: 12px;
      font-weight: bold;
      color: var(--font);
    }
  }

  .syntax-highlighter {
    box-sizing: border-box;
  }

  .code {
    // font-family: monospace;
    // font-size: 16px;
    // background-color: black;
    // color: white;

    width: 100%;
    box-sizing: border-box;

    color: rgb(197, 200, 198);
    text-shadow: rgba(0, 0, 0, 0.3) 0px 1px;
    font-family: Inconsolata, Monaco, Consolas, "Courier New", Courier,
      monospace;
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    line-height: 1.5;
    tab-size: 4;
    hyphens: none;
    padding: 1em;
    margin: 0.5em 0px;
    overflow: auto;
    border-radius: 10px !important;
    background: rgb(29, 31, 33);

    font-size: 12px;

    &::placeholder {
      color: rgba(255 255 255 / 30%);
    }
  }

  .color-change-button {
    margin-left: auto;
    margin-top: 10px;
    width: 30px;
    height: 30px;
    border-radius: 100px;

    box-shadow: 0 0 15px rgba(0 0 0 / 30%);
    border: 2px solid white;

    &.darker {
      background-color: rgba(0 0 0 / 50%);
    }

    &.lighter {
      background-color: rgba(255 255 255 / 50%);
    }

    &.none {
      background-color: transparent;
    }
  }

  &.darker {
    background-color: rgba(0, 0, 0, 10%);
  }

  &.lighter {
    background-color: rgba(255, 255, 255, 30%);
  }

  &.none {
    background-color: transparent;
  }

  .copy-section {
    display: flex;
    gap: 10px;

    margin-left: auto;

    .copy-text {
      font-weight: bold;
      font-size: 12px;
    }

    .copy-icon {
    }
  }

  


}
