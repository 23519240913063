.content-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;

  transition: opacity 0.2s ease;

  .badge {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-30%, -30%);
    z-index: 1;

    width: 15px;
    height: 15px;

    border-radius: 100px;
    background-color: orange;
  }

  .select {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 100px;
    border: 3px solid var(--bgGrayDark);

    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: 0 0 10px rgba(0 0 0 / 10%);

    transform: translate(30%, -30%);

    .icon {
      font-size: 12px;
      font-weight: bold;
      color: var(--bgGrayDark);
    }

    &.selected {
      border-color: var(--theme);
      background-color: var(--theme);
      .icon {
        color: white;
      }
    }
  }

  .content-card-wrapper {
    box-sizing: border-box;

    border: 1px solid rgb(216, 216, 216);

    box-shadow: 0 0 20px rgba(0 0 0 / 10%);

    // background-color: white;
    background-color: var(--bgGray);

    cursor: pointer;

    border-radius: 15px;
    overflow: hidden;
    // gap: 8px;

    &.selected {
      border: 2px solid var(--theme);
    }

    .content-container {
      padding: 15px 10px;
    }

    .content-container-small {
      padding: 7px 10px;
    }

    &.unavailable {
      opacity: 0.5;
    }

    &.white-theme {
      box-shadow: 0 0 20px rgba(0 0 0 / 10%);
      background-color: rgba(255 255 255 / 90%);
      border: 2px solid white;

      .contents-title {
        color: var(--theme) !important;
      }

      .contents-description {
        color: var(--theme) !important;
      }
    }

    &.white-skelton {
      box-shadow: none;
      background-color: transparent;
      border: 2px solid white;

      .contents-title {
        color: white !important;
      }

      .contents-description {
        color: white !important;
      }
    }

  }
}
