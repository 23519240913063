.content-footer-wrapper {
  position: fixed;
  bottom: 0px;
  width: 100%;

  padding: 20px 0;

  box-sizing: border-box;

  z-index: 998;

  display: flex;
  justify-content: center;

  // backdrop-filter: blur(3px);

  &.edit-mode,
  &.full-content {
    backdrop-filter: none;
  }

  transition: background 1s ease, box-shadow 1s ease;

  .content-footer-container {
    position: relative; // for app guide

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;

    width: 85%;
    max-width: 500px;

    .content-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      // background-color: rgba(0, 0, 0, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.4);
      box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
      border-radius: 100px;
      padding: 10px 15px;
      box-sizing: border-box;

      backdrop-filter: blur(3px);

      width: 100%;

      transition: background-color 0.3s ease, box-shadow 0.3s ease,
        color 0.3s ease;

      &.white-back {
        background-color: white;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);

        color: var(--font) !important;

        .separator {
          background-color: var(--font) !important;
        }
      }

      .next-message {
        display: none;
      }

      @media (pointer: coarse) {
        .next-message {
          display: block;
          font-size: 10px;
          font-weight: bold;
          margin-left: auto;
          white-space: nowrap;
        }
      }

      .page-icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 5px;
        box-sizing: border-box;
        border-radius: 100px;
        transition: opacity 0.3s ease;

        cursor: pointer;

        @keyframes emphasizeAnimate {
          /* 開始地点 */
          0% {
            transform: scale(1);
            box-shadow: none;
            background-color: transparent;
          }
          /* 終了地点 */
          100% {
            transform: scale(1.3);
            box-shadow: 0 0 20px white;
            background-color: rgba(255 255 255 / 70%);
          }
        }

        &.emphasize {
          animation-name: emphasizeAnimate;
          /* アニメーションの１回分の長さ */
          animation-duration: 2s;
          /* アニメーションの回数 */
          animation-iteration-count: infinite;
          /* アニメーションの進行具合 */
          animation-timing-function: ease-in-out;
          /* アニメーション再生の向き */
          animation-direction: alternate;
        }

        &.disabled {
          opacity: 40%;
        }

        .page-icon {
          width: 20px;
          height: 20px;
        }

        .prev-slide {
          transform: rotate(-90deg);
        }

        .next-slide {
          transform: rotate(90deg);
        }
      }
    }

    .footer-slide-title-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;

      .chapter-title {
        opacity: 50%;
        font-size: 12px;
        font-weight: bold;
      }

      .footer-slide-title {
        font-size: 14px;
        font-weight: bold;
      }
    }

    .progress-bar-section {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      width: 100%;

      &.edit-mode {
        padding: 5px;
        box-sizing: border-box;
        background-color: white;

        border-radius: 100px;

        width: 80%;

        opacity: 80%;

        .progress-bar-wrapper {
          background-color: rgba(0, 0, 0, 0.3);

          .progress-bar {
            background-color: var(--font);
          }
        }

        .page-index-section {
          color: var(--font);
        }
      }

      .progress-bar-wrapper {
        height: 2px;
        background-color: rgba(0, 0, 0, 0.3);
        width: 80%;

        border-radius: 100px;

        .progress-bar {
          height: 100%;
          background-color: white;
          border-radius: 100px;
          transition: width 0.3s ease;
        }
      }

      .progress-bar-wrapper-new {
        position: relative;

        height: 3px;
        width: 80%;

        background-color: rgba(0, 0, 0, 0.3);

        border-radius: 100px;

        .progress-bars {
          height: 100%;
          display: flex;

          transition: width 0.3s ease;
        }

        .progress-bars-overlay {
          position: absolute;
          top: 50%;
          left: 0;

          transform: translateY(-50%);

          width: 100%;
          height: 100%;

          display: flex;

          .progress-bar {
            position: relative;
            width: 100%;
            border-radius: 100px;

            cursor: pointer;

            .progress-bar-index {
              position: absolute;
              top: 50%;
              right: 0;

              transform: translateY(-50%);

              border-radius: 100px;

              &.child-slide {
                width: 5px;
                height: 5px;
              }

              &.parent-slide {
                width: 10px;
                height: 10px;
              }


            }
          }
        }
      }

      .page-index-section {
        opacity: 50%;
        span {
          display: block;
          font-size: 12px;
        }
      }
    }

    .next-slide-title-section {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;

      font-size: 10px;

      .next-que {
        font-weight: bold;
      }

      .next-slide-title {
        opacity: 70%;

        max-width: 60%;
        text-overflow: ellipsis;

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
}
