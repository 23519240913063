.link-block-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;

  position: relative;
  // z-index: 998;

  .link-wrapper {
    border-radius: 10px;
    box-sizing: border-box;

    text-decoration: none;

    background-color: rgba(0 0 0 / 10%);


    display: flex;
    gap: 15px;
    align-items: center;

    padding: 10px 10px 10px 15px;

    &.edit-mode {
      padding: 20px;
    }

    .link-favicon {
      width: 25px;
      height: 25px;
      object-fit: contain;
      border-radius: 100px;
    }

    .link-icon {}

    .link-img {
      // width: 100px;
      // height: 100px;
      width: 35%;
      object-fit: cover;
      border-radius: 10px;
    }

    .link-texts {

      display: flex;
      flex-direction: column;
      gap: 5px;

      width: 100%;

      span {
        display: block;

        &.link-title {
          font-weight: bold;
        }

        &.site-title {
          font-weight: bold;
          font-size: 12px;
          opacity: 50%;
        }

        &.link-url {
          font-size: 10px;
          opacity: 50%;
        }
      }
    }
  }

  .caption {
    margin-top: 5px;
    font-size: 12px;
  }
}

.link-edit-contents {
  color: black;

  display: flex;
  flex-direction: column;
  gap: 10px;

  .link-edit-content {

    display: flex;
    flex-direction: column;
    gap: 5px;

    .link-edit-content-title {
      display: block;
      font-size: 12px;
      font-weight: bold;
    }

    .link-content-input {
      padding: 10px;
      box-sizing: border-box;
      border-radius: 5px;
      border: 2px solid black;
  
      width: 100%;
    }

  }

  
}