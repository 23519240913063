.feature-start-wrapper {
  width: 100%;
  
  .feature-start-section {
    // min-height: 60vh;

    display: flex;
    flex-direction: column;
    justify-content: center;

    .feature-start-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 30px;

      background-color: rgba(255 255 255 / 30%);
      backdrop-filter: blur(50px);
      border-radius: 20px;
      box-shadow: 0 0 20px rgba(0 0 0 / 10%);

      padding: 30px 20px;
      box-sizing: border-box;

      .header-section {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 25px;

        width: 90%;
        margin: 0 auto;

        .icon {
          font-size: 32px;
          transform: rotate(-10deg);
          color: var(--grayFont);
        }

        .message-section {
          display: flex;
          flex-direction: column;
          gap: 3px;
  
          .header {
            color: var(--ink);
            font-size: 24px;
            font-weight: bold;
          }
  
          .message {
            margin: 0;
            color: var(--grayFont);
          }
        }
      }

      

      .button {
        padding: 15px 20px;
        box-sizing: border-box;
        border-radius: 100px;
        border: 1px solid white;
        background-color: var(--theme);

        display: flex;
        align-items: center;
        justify-content: center;

        width: 80%;
        margin: 0 auto;

        span {
          display: block;
          font-size: 14px;
          font-weight: bold;
          color: white;
        }
      }
    }
  }
}