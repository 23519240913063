.sns-section {
  font-size: 14px;

  padding: 20px;
  border-radius: 10px;
  background-color: rgba(255, 153, 0, 5%);

  .sns-header {
    display: block;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
  }

  .sns-message {}

  .instagram-wrapper {
    display: block;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 100px;
    box-shadow: 0 0 20px rgba(0 0 0 / 10%);
    margin: 0;

    color: rgb(57, 57, 57);

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    background-color: white;
    

    .ig-icon {
      width: 20px;
      height: 20px;
    }

    span {
      display: block;
    }
  }
}