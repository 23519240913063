.navigation-progress {
  position: fixed;

  &.top {
    top: 80px;
  }

  &.bottom {
    bottom: 140px;
  }
  
  left: 50%;
  transform: translateX(-50%);

  padding: 10px;
  background: white;

  border-radius: 100px;
  box-shadow: 0 0 20px rgba(0 0 0 / 15%);

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  .message {
    font-size: 12px;
    font-weight: bold;
  }
}