.app-footer-wrapper {
  position: fixed;
  bottom: 0px;

  z-index: 998;

  width: 100vw;
  
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 20px;
  padding-bottom: 20px;

  .app-footer-container {
    width: 100%;

    padding: 0 30px;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    max-width: 600px;

    background-color: white;
    border-radius: 100px;
    box-shadow: 0 0 20px rgba(0 0 0 / 20%);


    .app-footer-button {
      padding: 10px 0;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 3px;

      color: var(--grayFont);

      transition: all .3s ease;

      &:hover {
        transform: scale(1.2);
      }

      &.create-button {
        position: relative;
  
        width: 55px;
        height: 55px;

        padding: 10px;
        box-sizing: border-box;

        

        transform: translateY(-20%);

        background-color: var(--theme);
        border-radius: 100px;
        
        border: 3px solid white;
        box-shadow: 0 0 10px rgba(0 0 0 / 15%);
        

        &:hover {
          transform: translateY(-25%);
        }

        color: white;

        .app-footer-icon {
          width: 23px;
          height: 23px;
        }
      }

      &.active {
        // color: var(--theme);
        // border-bottom: 3px solid var(--theme);

        color: var(--ink);
        border-bottom: 3px solid var(--ink);

        transform: scale(1.1);

        .button-name {
          font-weight: bold;
        }
      }

      .app-footer-icon {
        width: 20px;
        height: 20px;
      }

      .button-name {
        font-size: 8px;
      }

    }
  }
}
