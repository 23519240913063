.feed-post-section {
  display: flex;
  flex-direction: column;
  gap: 20px;


  .button-section {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}