.loading-wrapper {
  width: 100vw;
  height: 100vh;

  // dotted grid background
  background: rgb(246, 246, 246);
  background-image: radial-gradient(rgb(205, 205, 205) 1px, transparent 0);
  background-size: 20px 20px;
  background-position: -19px -19px;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px;

  box-sizing: border-box;

  .loading-message {
    span {
      font-size: 14px;
      font-weight: bold;

      color: rgb(55, 55, 55);
    }
  }
}

.slide-view-wrapper {
  width: 100vw;

  display: flex;
  justify-content: center;

  box-sizing: border-box;

  // dotted grid background
  background: white;
  background-image: radial-gradient(rgb(0, 0, 0) 1px, transparent 0);
  background-size: 20px 20px;
  background-position: -19px -19px;

  transition: background 1s ease;

  input,
  textarea {
    background-color: transparent;
    color: inherit;
    height: auto;
    resize: none;
    padding: 0;
  }

  .slide-contents-wrapper {
    position: relative;

    width: 100%;
    max-width: 500px;

    min-height: 100vh;

    box-sizing: border-box;
    padding: 80px 0px;
    padding-bottom: 170px;

    // centering task
    display: flex;
    flex-direction: column;

    transition: padding-bottom .7s cubic-bezier(0.21, 1.29, 0.36, 0.96);

    .slide-contents-window {
      position: relative;
      transition: padding 0.3s ease, margin 0.4s ease, border-radius 0.5s ease,
        background 1s ease;

      &:not(.edit-mode) {
        min-height: 100%;
      }

      &.edit-mode {
        margin: 130px 20px;
        margin-top: 250px;
        padding: 50px 0px;

        border-radius: 20px;
        border: 4px solid white;
        box-shadow: 0 0 30px rgba(0 0 0 / 20%);
      }

      .slide-contents {
        padding: 0px 20px;

        // centering task
        display: flex;
        height: 100%;
        flex-direction: column;

        // overflow: hidden;

        .slide-blocks {
          position: relative; // for app guide

          margin-top: 40px;
          margin-bottom: 40px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 20px;

          height: 100%;

          &.edit-mode {
            gap: 40px;
          }

          .slide-title-for-empty-slide {
            display: flex;
            align-items: center;
            justify-content: center;

            .slide-title {
              display: block;
              font-size: 18px;
              font-weight: bold;
              text-align: center;
              padding: 10px 0;
            }
          }

          .no-blocks {
            font-size: 12px;
            font-weight: bold;
            opacity: 50%;
            text-align: center;
          }
        }

        .next-button {
          margin-top: 50px;
          padding: 20px;
          box-sizing: border-box;
          border-radius: 10px;
          border: 2px solid;
          width: 100%;

          span {
            display: block;
            width: 100%;
            text-align: center;
            font-weight: bold;
            font-size: 16px;
          }
        }
      }
    }

    .window-page-buttons {
      .next-page,
      .previous-page {
        position: absolute;
        width: 30%;
        height: 100%;
        z-index: 997;
      }

      .next-page {
        top: 0;
        right: 0;
      }

      .previous-page {
        top: 0;
        left: 0;
      }
    }

    .chat-button {
      position: fixed;
      bottom: 30px;
      left: 15px;
      background-color: white;
      padding: 15px 20px;
      border-radius: 100px;

      box-sizing: border-box;
      box-shadow: 0 0 20px rgba(0 0 0 / 15%);

      border: 3px solid orange;

      cursor: pointer;

      z-index: 998;

      span {
        display: block;
        color: orange;
        font-size: 14px;
        font-weight: bold;
      }
    }

    .content-buttons {
      position: fixed;
      bottom: 30px;
      right: 15px;

      display: flex;
      flex-direction: column;
      align-items: end;
      gap: 10px;

      z-index: 998;

      .content-button {
        border-radius: 100px;
        background-color: white;
        box-sizing: border-box;
        box-shadow: 0 0 20px rgba(0 0 0 / 15%);
        cursor: pointer;

        padding: 10px;

        width: 50px;
        height: 50px;

        display: flex;
        align-items: center;
        justify-content: center;

        transition: background-color 0.3s ease, border 0.3s ease,
          color 0.3s ease;
      }

      .copy-link {
      }

      .edit-mode-button {
        &.edit-mode {
          border: 3px solid white;
          background-color: #4abde1;
          color: white;
        }
      }
    }
  }

  .qr-wrapper {
    background-color: var(--bgGray);
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: center;

    box-shadow: 0 0 10px rgba(0 0 0 / 20%) inset;

    #qr-image {
      border-radius: 10px;
      border: 2px dashed var(--theme);
    }
  }

  .select-block-contents {
    // display: flex;
    // flex-direction: column;
    // gap: 10px;
    // width: 100%;

    // margin-top: 20px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    .type {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 10px;

      background-color: rgb(246, 246, 246);

      color: rgb(66, 66, 66);

      &.admin-only {
        box-shadow: 0 0 5px var(--purple);
        border: 0.5px solid var(--purple);
      }

      span {
        display: block;
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
}
