.space-page-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  .message {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
  }
}