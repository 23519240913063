.component-wrapper {
  width: 100%;
  box-sizing: border-box;

  transition: box-shadow .3s ease, border .3s ease, margin .3s ease, background-color .3s ease, transform .3s ease;

  &.shadowed {

    &.darker {
      box-shadow: 10px 10px 0px rgba(0 0 0 / 40%);
      // box-shadow: 1px 1px rgba(0 0 0 / 40%), 2px 2px rgba(0 0 0 / 40%), 3px 3px rgba(0 0 0 / 40%), 4px 4px rgba(0 0 0 / 40%), 5px 5px rgba(0 0 0 / 40%), 6px 6px rgba(0 0 0 / 40%);
      border: 3px solid rgba(0 0 0 / 70%);
    }

    &.lighter {
      box-shadow: 10px 10px 0px rgba(255 255 255 / 70%);
      // box-shadow: 1px 1px rgba(255 255 255 / 50%), 2px 2px rgba(255 255 255 / 50%), 3px 3px rgba(255 255 255 / 50%), 4px 4px rgba(255 255 255 / 50%), 5px 5px rgba(255 255 255 / 50%), 6px 6px rgba(255 255 255 / 50%);
      border: 3px solid rgba(255 255 255 / 80%);
    }
  }
  
  p {
    margin: 0;
  }
}