.notification-test-wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;

  // ------ section specific ----------

  .status-section {
    .other-subscriptions {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }

  .buttons-section {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .send-section {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .send-section-inputs {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .send-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }
}
