.form-section {
  display: flex;
  flex-direction: column;
  gap: 15px;

  padding: 30px 0;

  border-bottom: 1px dotted var(--lineGray);

  &:first-child {
    padding-top: 15px;
  }

  textarea {
    min-height: 90px;
    resize: none;
  }

  .form-section-title-section {
    display: flex;
    flex-direction: column;
    gap: 3px;

    .form-section-title {
      font-weight: bold;
      font-size: 14px;
    }

    .form-section-description {
      margin: 0;
      font-size: 12px;
      color: var(--grayFont);
    }
  }
}
