// ----- Fonts ---------------
@import url("https://fonts.googleapis.com/css2?family=Kadwa:wght@400;700&family=Yomogi&display=swap");

:root {
  // ----- Variables --------------
  // ----- Brand Colors -----------------
  --theme: #4abde1;
  --themeGradient: linear-gradient(
    163deg,
    rgba(190, 231, 244, 1) 0%,
    rgba(74, 189, 225, 1) 100%
  );
  --passion: #ff7f47;
  --curiocity: #4abde1;
  --paper: #f1ede2;
  --paperSoft: #fcfaf3;
  --ink: #343a4b;

  // ----- Content Colors ---------------
  // font colors
  --font: #292e34;
  --grayFont: #979797;

  // line colors
  --lineGray: #cfcfcf;

  // background colors
  --bgGray: #f6f6f6;
  --bgGrayDark: #e2e2e2;

  // ----- Status Colors --------------
  --green: #439b51;
  --red: #e96363;

  // ----- limited colors ---------
  --purple: #d846f3;
  --gold: #edc547;
}

* {
  backface-visibility: hidden; /* animation時のにじみ防止 */
  -webkit-backface-visibility: hidden; /* animation時のにじみ防止 */
  -webkit-font-smoothing: antialiased; /* animation時の文字にじみ防止 */
  -moz-osx-font-smoothing: grayscale; /* animation時の文字にじみ防止 */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

input:not([type="checkbox"]),
textarea {
  background-color: var(--bgGray);
  width: 100%;
  box-sizing: border-box;

  border: none;
  padding: 10px;
  border-radius: 5px;

  outline: none;
}

input::placeholder,
textarea::placeholder {
  color: rgba(0 0 0 / 30%);
}

span:not(.syntax-highlighter *),
p:not(.syntax-highlighter *),
pre:not(.syntax-highlighter *),
input:not(.syntax-highlighter *),
textarea:not(.syntax-highlighter *) {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  white-space: pre-wrap; /* allow wrapping of long lines */
  word-break: break-word; /* allow breaking of words at any character */
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.underline {
  text-underline-offset: 4px;
  text-decoration: dashed underline;
}

.cursor-pointer {
  cursor: pointer;
}

.shadow-on-hover {
  transition: all 0.3s ease !important;

  &:hover {
    box-shadow: 0 0 20px rgba(0 0 0 / 5%);
  }
}

.scale-on-hover {
  transition: all 0.3s ease !important;

  &:hover {
    transform: scale(1.05);
  }
}

.scale-on-hover-small {
  transition: all 0.3s ease !important;

  &:hover {
    transform: scale(1.02);
  }
}
