.carousel-component-section {
  width: 100%;

  .carousel-test {

    padding: 20px;
    box-sizing: border-box;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

    background-color: var(--bgGray);

    color: var(--font);

    .title {
      font-size: 22px;
      font-weight: bold;
    }

    .image {
      display: block;
      width: 100%;
      object-fit: contain;
      border-radius: 10px;
      overflow: hidden;
    }

    .text {
      margin: 0;
      font-size: 14px;
    }
  }
}
