.content-header-section {
  position: relative;
  z-index: 998;

  width: 100%;

  .content-header-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;

    .title-wrapper {
      width: 100%;

      .content-title-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

        // .content-title {
        //   display: block;
        //   width: 100%;
        //   font-size: 14px;
        //   color: rgba(0, 0, 0, 15%);
        //   font-weight: bold;
        //   text-align: center;

        //   padding: 10px;
        //   box-sizing: border-box;
        //   border: 1.5px dashed rgba(0, 0, 0, 15%);
        // }

        .content-title {
          font-size: 14px;
          color: var(--font);
          font-weight: bold;

          display: -webkit-box;
          -webkit-line-clamp: 2; // <- you can change rows number
          -webkit-box-orient: vertical;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .content-edit-button {
          padding: 10px;

          background-color: white;

          border-radius: 100px;
          box-shadow: 0 0 10px rgba(0 0 0 / 10%);

          display: flex;
          align-items: center;
          justify-content: center;

          .content-edit-button-icon {
            font-size: 18px;
            color: var(--theme);
          }
        }
      }

      .slide-page-bars {
        display: flex;
        gap: 8px;

        .slide-page-bar {
          width: 100%;
          border: 2px solid rgba(0, 0, 0, 15%);
          height: 10px;
          border-radius: 100px;

          box-sizing: border-box;

          cursor: pointer;

          &.locked {
            background-color: rgba(0, 0, 0, 10%);
            border: none;

            &.active {
              background-color: rgba(0, 0, 0, 70%);
            }
          }

          &.active {
            background-color: rgba(0, 0, 0, 30%);
          }
        }
      }
    }

    .add-slide-before,
    .add-slide-after {
      display: none;
      cursor: pointer;

      width: fit-content;
    }

    .add-slide-after {
      position: relative; // for app guide
    }
  }

  .slide-complexity {
    display: none;
  }

  &.edit-mode {
    position: fixed;
    // background-color: rgb(245, 245, 245);
    background-color: white;
    width: 100%;
    top: 0;
    left: 0;

    z-index: 999;

    box-shadow: 0 0 20px rgba(0 0 0 / 20%);

    padding: 10px 0;
    padding-top: 30px;
    box-sizing: border-box;

    border-radius: 0 0 10px 10px;

    transition: background-color 1s ease;

    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;

    .slide-complexity {
      background-color: white;
      display: flex;
      gap: 10px;
      width: fit-content;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      border: 1.5px solid rgb(224, 224, 224);

      padding: 10px;

      box-sizing: border-box;

      .complexity-guage-wrapper {
        display: flex;
        gap: 2px;

        .complexity-guage {
          width: 40px;
          height: 10px;

          border: 2px solid rgb(224, 224, 224);
          box-sizing: border-box;

          border-radius: 2px;

          &:nth-child(1) {
            border-radius: 100px 10px 10px 100px;
          }

          &:nth-child(3) {
            border-radius: 10px 100px 100px 10px;
          }

          &.intensity-1 {
            background-color: #4abde1;
            border-color: #4abde1;
          }

          &.intensity-2 {
            background-color: rgb(76, 187, 98);
            border-color: rgb(76, 187, 98);
            border-width: 1px;
          }

          &.intensity-3 {
            background-color: orange;
            border-color: orange;
            border-width: 1px;
          }
        }
      }

      span {
        font-size: 12px;
        font-weight: bold;
      }

      .text {
        color: rgb(64, 64, 64);
      }

      .complexity {
        &.intensity-1 {
          color: #4abde1;
        }

        &.intensity-2 {
          color: rgb(76, 187, 98);
        }

        &.intensity-3 {
          color: orange;
        }
      }
    }

    .saving-message {
      span {
        font-size: 12px;
        font-weight: bold;
        color: gray;
      }
    }

    .add-slide-before,
    .add-slide-after {
      min-width: 30px;
      min-height: 30px;

      // background-color: rgb(170, 170, 170);
      background: var(--themeGradient);
      box-shadow: 0 0 10px rgba(0 0 0 / 10%);
      border: 3px solid white;
      padding: 7px;

      box-sizing: border-box;

      display: flex;
      align-items: center;
      justify-content: center;

      span {
        display: block;
        font-weight: bold;
        color: white;
        font-size: 14px;
      }
    }

    .add-slide-before {
      border-radius: 0 100px 100px 0;
      border-left: none;
    }

    .add-slide-after {
      border-radius: 100px 0 0 100px;
      border-right: none;
    }
  }
}
