.admin-section {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .section-info {
    display: flex;
    flex-direction: column;
    gap: 3px;

    .section-title {
      display: block;
      font-size: 14px;
      font-weight: bold;
    }

    .section-description {
      margin: 0;
      font-size: 12px;
      color: var(--grayFont);
    }
  }

  .section-contents {
    font-size: 12px;
  }
}

.admin-input-component, .admin-textarea-component {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .header {
    font-weight: bold;
  }
}

.admin-textarea-component {
  textarea {
    min-height: 100px;
    resize: none;
  }
}

.status {
  padding: 10px 0;
  border-bottom: 1px dashed var(--lineGray);

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  .status-name {
    font-weight: bold;
    color: var(--grayFont);
  }

  .status-value {
    max-width: 50%;
    font-weight: bold;

    &.normal {
      font-weight: normal;
    }

    &.green {
      color: var(--green);
    }
    &.red {
      color: var(--red);
    }
    &.gray {
      color: var(--grayFont);
    }
  }
}




.admin-button {
  padding: 10px;
  border-radius: 100px;
  border: 2px solid var(--ink);

  width: fit-content;
  min-width: 80px;

  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: block;
    font-size: 12px;
    font-weight: bold;
    color: var(--ink);
  }

  &.dark {
    background-color: var(--ink);

    span {
      color: white;
    }
  }

  &.disabled {
    opacity: 50%;
  }

}