.slide-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  z-index: 999;

  pointer-events: none; // make underlying content clickable

  color: var(--font);


  .slide-modal-container {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;

    
    width: calc(100% - 15px);
    

    pointer-events: auto; // make modal clickable

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    .title-section {
      padding: 15px 20px;
      background-color: white;

      box-shadow: 0 0 50px rgba(0 0 0 / 25%);

      border-radius: 100px;

      display: flex;
      justify-content: center;
      align-items: center;

      span {
        display: block;
        font-weight: bold;
        font-size: 12px;
        color: var(--ink);
      }
    }

    .slide-modal-card {
      position: relative;
      top: 0;
      left: 0;


      width: 100%;
      height: 100%;

      background-color: white;
      border-radius: 35px 35px 0 0;
      
      box-shadow: 0 0 50px rgba(0 0 0 / 25%);
  
      border: 5px solid var(--bgGray);
      border-bottom: none;

      display: flex;
      flex-direction: column;
      align-items: center;

      .slide-modal-header-section {
        position: absolute;
        top: 0;
        left: 0;

        z-index: 999;
  
        width: 100%;
  
        display: flex;
        align-items: center;
        justify-content: center;
  
        padding-top: 10px;
        padding-bottom: 20px;
  
        .slide-modal-toggle {
          width: 100px;
          height: 7px;
          background-color: var(--bgGrayDark);
          border-radius: 100px;
        }
  
        .slide-modal-close {
          position: absolute;
          top: 15px;
          right: 20px;
        }
      }
  
      
  
      .slide-modal-content {
        box-sizing: border-box;
  
        width: 100%;
        height: 100%;
        max-width: 500px;
  
        padding: 60px 20px;
        margin-bottom: 50px;
  
        overflow-y: scroll;
      }
    }

    
  }
}