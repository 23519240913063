.contents-list-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  .no-contents {
    display: block;
    text-align: center;
    font-size: 14px;
    color: gray;

    font-weight: bold;
  }

  .search-box {
    width: 100%;
  }

  .selected-message {
    font-weight: bold;
    font-size: 14px;
  }

  .columns-wrapper {
    display: flex;
    justify-content: center;
    gap: 15px;

    width: 100%;
  }

  .contents {
    display: flex;
    flex-direction: column;
    gap: 15px;

    width: 50%;

    .content-wrapper-for-app-guide {
      position: relative;

    }

    .content-list-content-wrapper {
      height: 100%;

      transition: transform 0.3s ease;

      &:hover {
        transform: rotate(0deg) translate(0px, 0px) !important;
      }
    }
  }
}
