.form-block-wrapper {
  width: 100%;
  background-color: white;
  border-radius: 15px;
  padding: 20px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  gap: 25px;

  box-shadow: 0 0 20px rgba(0 0 0 / 10%);

  overflow: hidden;

  .line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed var(--lineGray);
  }

  &.edit-mode {
    position: relative;

    .form-block-edit-overlay {
      position: absolute;
      z-index: 998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255 255 255 / 60%);
      backdrop-filter: blur(0.8px);

      display: flex;
      align-items: center;
      justify-content: center;

      span {
        display: block;
        font-size: 14px;
        color: var(--ink);
        font-weight: bold;

        padding: 10px 20px;
        border-radius: 100px;
        border: 2px solid var(--ink);
      }
    }
  }

  .form-block-title-section {
    display: flex;
    flex-direction: column;
    gap: 15px;

    color: var(--ink);

    .form-block-title {
      display: flex;
      align-items: center;
      gap: 10px;

      .form-block-icon {
        font-size: 18px;
      }

      .form-block-title-text {
        font-size: 22px;
        font-weight: bold;
      }
    }

    .form-block-message {
      margin: 0 auto;
      font-size: 14px;
      font-weight: bold;
      color: var(--grayFont);
      max-width: 90%;
      text-align: center;
    }
  }

  .form-block-complete-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    padding: 20px;
    box-sizing: border-box;
    background-color: var(--bgGray);
    border-radius: 20px;

    .form-block-complete-icon {
      font-size: 30px;
      color: var(--green);
    }

    span {
      color: var(--ink);
      font-weight: bold;
      font-size: 18px;
    }
  }

  .form-block-form-section {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .form-block-form-wrapper {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .form-block-form-title {
        font-size: 12px;
        font-weight: bold;
        color: var(--ink);
        padding-left: 10px;
        box-sizing: border-box;
      }

      .form-block-form {
        border: 2px solid var(--ink);
        padding: 15px;
        box-sizing: border-box;
        border-radius: 100px;

        font-weight: bold;
        font-size: 14px;
        color: var(--ink);

        &.edit-mode {
          border: none;
          background-color: var(--bgGray);
          span {
            font-size: 12px;
            color: var(--grayFont);
          }
        }
      }
    }
  }

  .form-block-button {
    width: 100%;
    background-color: var(--ink);

    padding: 15px 20px;
    box-sizing: border-box;
    border-radius: 100px;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: background-color 0.3s ease;

    span {
      font-size: 14px;
      font-weight: bold;
      color: white;

      transition: color 0.3s ease;
    }

    &.disabled {
      background-color: var(--bgGray);

      span {
        color: var(--grayFont);
      }
    }
  }
}

.form-block-edit-modal-wrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;

  .form-block-edit-modal-forms {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .form-block-edit-modal-form {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .form-title {
        font-weight: bold;
        font-size: 12px;
        padding-left: 15px;
        box-sizing: border-box;
      }

      .form {
        border-radius: 100px;
        background-color: var(--bgGray);
        padding: 15px 20px;
        box-sizing: border-box;

        font-weight: bold;
        font-size: 16px;
      }

      .form-text {
        border-radius: 20px;
        resize: none;
        min-height: 100px;
        background-color: var(--bgGray);

        padding: 15px 20px;

        font-weight: bold;
        font-size: 14px;
      }
    }
  }

  .form-block-edit-modal-button {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 15px 20px;
    box-sizing: border-box;
    border-radius: 100px;

    background-color: var(--ink);

    span {
      font-weight: bold;
      font-size: 14px;
      color: white;
    }
  }
}
