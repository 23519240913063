@import url("https://fonts.googleapis.com/css2?family=Yomogi&display=swap");

@keyframes UpDown {
  /* 開始地点 */
  0% {
    /* Y軸0px */
    transform: translateY(-5px);
  }
  /* 終了地点 */
  100% {
    /* Y軸50px */
    transform: translateY(5px);
  }
}

@keyframes LeftRight {
  /* 開始地点 */
  0% {
    /* Y軸0px */
    transform: translateX(-5px);
  }
  /* 終了地点 */
  100% {
    /* Y軸50px */
    transform: translateX(5px);
  }
}

@keyframes shadowAnimate {
  /* 開始地点 */
  0% {
    /* Y軸0px */
    box-shadow: 0 0 10px #4abde1;
    // border: 2px dashed #4abde1;
  }
  /* 終了地点 */
  100% {
    /* Y軸50px */
    box-shadow: 0 0 30px #4abde1;
    // border: 2px dashed rgba(255, 166, 0, 0.347);
  }
}

.app-guide {
  position: absolute;
  top: 0;
  left: 0;

  // z-index: 999;
  z-index: 998;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  padding: 10px 20px;
  background-color: white;
  border-radius: 10px;
  border: 2px solid #4abde1;

  box-sizing: border-box;

  animation-name: shadowAnimate;
  /* アニメーションの１回分の長さ */
  animation-duration: 1s;
  /* アニメーションの回数 */
  animation-iteration-count: infinite;
  /* アニメーションの進行具合 */
  animation-timing-function: ease-in-out;
  /* アニメーション再生の向き */
  animation-direction: alternate;



  .close-button {
    position: absolute;
    top: 0;
    right: 0;

    transform: translate(40%, -40%);

    width: 20px;
    height: 20px;
    box-sizing: border-box;

    border-radius: 100px;
    background-color: var(--theme);

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    .close-icon {
      color: white;
    }

  }




  span {
    display: block !important;
    font-size: 14px !important;
    font-weight: bold !important;
    color: #4abde1 !important;
    white-space: nowrap !important;

    &.handwritten {
      font-size: 16px !important;
      font-family: Bradley Hand, "Yomogi", cursive !important;
    }
  }

  .pointer {
    font-size: 22px !important;
  }

  .vertical {
    // updown animation
    /* アニメーションの名前 */
    animation-name: UpDown;
    /* アニメーションの１回分の長さ */
    animation-duration: 1s;
    /* アニメーションの回数 */
    animation-iteration-count: infinite;
    /* アニメーションの進行具合 */
    animation-timing-function: ease-in-out;
    /* アニメーション再生の向き */
    animation-direction: alternate;
  }

  .horizontal {
    // updown animation
    /* アニメーションの名前 */
    animation-name: LeftRight;
    /* アニメーションの１回分の長さ */
    animation-duration: 1s;
    /* アニメーションの回数 */
    animation-iteration-count: infinite;
    /* アニメーションの進行具合 */
    animation-timing-function: ease-in-out;
    /* アニメーション再生の向き */
    animation-direction: alternate;
  }
}
