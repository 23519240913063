.contents-list-section {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .contents-wrapper {
    margin-top: 10px;

    .tap-message {
      margin: 0;
      margin-bottom: 20px;
      font-size: 12px;
      font-weight: bold;
      color: white;
      text-align: center;
    }
  }

  .all-contents-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .user-section {
      padding: 20px;
      background-color: rgba(0 0 0 / 5%);
      border-radius: 10px;

      .user-name-section {
        display: flex;
        align-items: center;
        gap: 5px;

        flex-wrap: wrap;

        span {
          display: block;
        }

        .user-email {
          color: gray;
          font-weight: bold;
        }

        .user-profile {
          color: var(--theme);
          font-weight: bold;
        }
      }

      

    }

    .contents {
      margin-top: 20px;
    }
  }
}