.create-content-modal-wrapper {
  font-size: 14px;
  color: var(--font);

  .form-sections {
    display: flex;
    flex-direction: column;
    gap: 20px;

    input {
      border-radius: 10px;
      border: 2px solid var(--font);
      background-color: white;
    }

    .section {
      width: 100%;

      display: flex;
      flex-direction: column;
      gap: 20px;

      &.slide-section-wrapper {
        padding: 20px;
        box-sizing: border-box;
        box-shadow: 0 0 20px rgba(0 0 0 / 10%);
        border: 1px solid var(--theme);
        background-color: white;
        border-radius: 10px;
      }

      .content-title-input {
        font-size: 18px;
        font-weight: bold;

        padding: 10px 0;

        border-radius: 0;
        border: none;
        border-bottom: 1.5px dashed var(--font);
      }

      .content-title-preview-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;

        .content-title-preview-header {
          font-size: 12px;
          font-weight: bold;
          color: var(--grayFont);
        }

        .content-title-preview {
          font-size: 18px;
          font-weight: bold;
        }
      }

      .section-title-wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .section-title {
          display: flex;
          align-items: center;
          gap: 10px;
          width: 100%;

          span {
            font-size: 18px;
            display: block;
            font-weight: bold;
            white-space: nowrap;
          }
        }

        .section-title::before {
          content: "";
          display: block;
          height: 10px;
          width: 10px;
          background-color: var(--bgGrayDark);
        }

        .section-description {
          font-size: 12px;
          color: var(--grayFont);
          margin: 0;
        }
      }

      .slide-titles {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .slide-title {
          display: flex;
          align-items: center;
          gap: 20px;
        }

        .delete-button {
          font-size: 18px;
          color: var(--red);

          cursor: pointer;
        }
      }

      .add-slide-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .add-slide-button {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;

          margin-top: 10px;

          width: fit-content;

          color: var(--grayFont);

          span {
            font-size: 14px;
            font-weight: bold;
            white-space: nowrap;
          }
        }
      }
    }

    .summary-section {
      width: 100%;
      border-top: 1px dashed var(--lineGray);
      border-bottom: 1px dashed var(--lineGray);
      margin-top: 15px;

      padding: 10px 0;

      display: flex;
      align-items: center;
      justify-content: center;

      gap: 10px;

      color: var(--green);

      span {
        font-weight: bold;
        font-size: 12px;
      }
    }
  }
}
