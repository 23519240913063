.profile-settings-wrapper {
  .handle-section-wrapper {
    .handle-form-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;

      .handle-icon {
        font-size: 22px;
        font-weight: bold;
      }
    }

    .handle-availability {
      font-weight: bold;

      display: flex;
      gap: 10px;
      align-items: center;

      &.green {
        color: var(--green);
      }

      &.red {
        color: var(--red);
      }

      .availability-icon {
        display: block;
        font-size: 12px;
      }

      .availability-text {
        display: block;
        font-size: 12px;
      }


    }
  }
}
