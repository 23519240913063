.full-video-block-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;

  &:not(.edit-mode) {
    position: fixed;
    top: 0;
    left: 0;
  }

  

  .loading-wrapper {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 1;

    transition: opacity .3s ease;

    &.hide {
      opacity: 0;
    }


    span {
      font-weight: bold;
      color: white;
    }
  }

  .full-video {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    max-width: 100%;
    height: 100vh;
    object-fit: cover;
    z-index: 997;
    transition: none !important;

    &.cropped {
      object-fit: contain;
    }
  }

  .caption {
    font-size: 12px;
  }


  .edit-video-wrapper {
    position: relative;

    .full-video {
      display: block;
      width: 100%;
      height: auto;
      object-fit: cover;
      max-height: 9999px;
      border-radius: 10px;

      position: relative;
      z-index: 998;

      &.cropped {
        object-fit: contain;
      }
    }

    .edit-aspect-ratio-section {
      position: absolute;
      z-index: 998;
      bottom: 10px;
      right: 10px;

      display: flex;
      align-items: center;
      gap: 10px;

      padding: 10px;
      background-color: rgba(0 0 0 / 30%);
      border-radius: 5px;

      .aspect-message {
        display: block;
        font-weight: bold;
        font-size: 10px;
      }

      .aspect-button {
        width: 20px;
        height: 20px;
        border: 2px dashed white;
        border-radius: 5px;

        background-color: rgba(255 255 255 / 30%);

        transition: height .5s ease;

        &.vertical {
          height: 30px;
        }
      }
    }



  }


}

.video-edit-contents {
  color: black;

  display: flex;
  flex-direction: column;
  gap: 10px;


  .video-wrapper {
    display: inline-block;
    width: fit-content;
    border-radius: 10px;
    overflow: hidden;

    .video {
      display: block;
      width: auto;
      max-height: 150px;
      border-radius: 10px;
      object-fit: contain;
    }
  }



  .video-url {
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 2px solid black;

    width: 100%;
  }
}