.loading-component-wrapper {
  width: 100%;
  height: 100%;

  // min-height: 300px;

  .loading-content {
    display: flex;
    align-items: center;
    justify-content: center;
    .loader {
      width: 32px;
      height: 32px;
      border: 5px solid var(--ink);
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
