.add-block-button-wrapper {
  width: 100%;

  display: flex;
  align-items: center;
  gap: 10px;

  .dotted-line {
    width: 100%;
    border-top: 1px dashed black;
  }

  

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .separator {
      height: 15px;
      width: 1px;
    }

    

    .slide-block-add-button {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 5px 10px;
      box-sizing: border-box;
      border-radius: 10px;
      border: 1px solid black;

      width: fit-content;

      margin: 0 auto;
      position: relative;
      z-index: 997;

      cursor: pointer;

      transition: background 1s ease;

      &.round {
        width: 30px;
        height: 30px;
        border-radius: 100px;
        padding: 10px;
      }

      &.emphasize {
        box-shadow: 0 0 10px rgba(0 0 0 / 10%);
      }

      &.no-border {
        border: none;
      }

      span {
        font-size: 12px;
        font-weight: bold;
        color: black;
        width: max-content;
      }
    }
  }
}


.move-block-modal {
  display: flex;
  flex-direction: column;
  gap: 30px;

  color: var(--font);

  .section {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .slide-title-input {
      background-color: var(--bgGray);
      padding: 10px;
    }

    .section-title {
      font-size: 14px;
      font-weight: bold;
    }

    .destination-list {
      display: flex;
      align-items: center;
      gap: 10px;

      .destination {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 10px;
        border-radius: 100px;
        border: 1px solid var(--grayFont);

        cursor: pointer;

        background-color: white;

        transition: background-color .3s ease;

        span {
          color: var(--grayFont);
          font-size: 12px;
          font-weight: bold;
        }

        &.selected {
          border: 1px solid var(--theme);
          background-color: var(--theme);
          box-shadow: 0 0 10px var(--theme);
          
          span {
            color: white;
          }
        }
        
      }
    }
  }


}