.app-wrapper {
  position: relative;
  width: 100vw;
}

.footer-screen-adapt {
  padding-bottom: 20px !important;

  @media all and (display-mode: fullscreen),
    all and (display-mode: standalone) {
    padding-bottom: 50px !important;
  }
}
