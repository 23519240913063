.people-wrapper {
  background-color: white;
  // border: 1px solid black;
  box-shadow: 0 0 20px rgba(0 0 0 / 10%);
  border-radius: 20px;

  display: flex;
  flex-direction: column;

  padding: 10px;

  .no-one-is-here {
    color: var(--grayFont);
    font-size: 14px;
    font-weight: bold;
    
    text-align: center;
  }

  .person-wrapper {

    padding: 0 10px;

    .line {
      width: 100%;
      height: 1px;
      // background-color: var(--lineGray);
      border-bottom: 1px dashed var(--lineGray);
    }

    .person-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      padding: 15px 0;

      .left,
      .right {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .right {
        .person-menu {
          color: var(--grayFont);
          margin-left: 10px;
        }
      }

      .index {
        color: var(--grayFont);
        font-size: 12px;
      }

      .name {
        color: var(--ink);
        font-weight: bold;
        font-size: 14px;

        &.gray {
          color: var(--grayFont);
          font-weight: normal;
          font-size: 12px;
          font-style: italic;
        }
      }
      .email {
        color: var(--grayFont);
        font-size: 12px;
      }
    }
  }

  .person-menu-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;

    .person-menu-person-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 5px;

      .name {
        color: var(--ink);
        font-weight: bold;
        font-size: 14px;
      }

      .email {
        font-size: 12px;
        color: var(--grayFont);
      }
    }
    
    .person-menu-buttons {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;

      width: 100%;
      
      .person-menu-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;

        padding: 15px 20px;
        box-sizing: border-box;
        background-color: var(--bgGray);
        border-radius: 100px;
        width: 80%;

        span {
          font-size: 14px;
          font-weight: bold;
        }

        &.red {
          color: var(--red);
        }
      }
    }
  }
}
