.home-wrapper {

  .home-container {
    display: flex;
    flex-direction: column;
    gap: 50px;

    .section {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .section-heading {
        display: block;
        font-size: 24px;
        font-weight: bold;
        color: rgb(41, 41, 41);
      }
    }

    .welcome-section {
      padding: 25px;
      // background-color: rgb(240, 246, 247);
      background: rgb(190, 231, 244);
      background: var(--themeGradient);
      border-radius: 20px;

      box-shadow: 0 0 20px rgba(0 0 0 / 10%);

      .section-heading {
        color: white;
      }

      .welcome-message {
        margin: 0;
        font-size: 14px;
        color: white;
        font-weight: bold;
      }

      .contents-wrapper {
        margin-top: 10px;

        .tap-message {
          margin: 0;
          margin-bottom: 20px;
          font-size: 12px;
          font-weight: bold;
          color: white;
          text-align: center;
        }
      }
    }

    .notification-test-section {
      border: 2px dashed var(--theme);
      border-radius: 20px;
      padding: 20px;

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      .notification-test-header {
        font-weight: bold;
        font-size: 18px;
      }

      .notification-status {
        .notification-test-header {
          font-size: 12px;
          font-weight: bold;
        }
        .notification-status {
          font-weight: bold;
          font-size: 22px;
          color: black;
        }
      }

      .notification-test-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .notification-test-button {
          width: fit-content;
          border: 2px solid var(--font);
          border-radius: 100px;
          padding: 10px 20px;
  
          span {
            font-size: 14px;
            font-weight: bold;
          }
        }
      }

      
    }


    .add-content-button {
      position: fixed;
      bottom: 30px;
      right: 20px;
      background-color: #4abde1;

      width: fit-content;

      padding: 15px;
      border-radius: 100px;
      box-sizing: border-box;
      border: 3px solid white;

      box-shadow: 0 0 20px rgba(0 0 0 / 20%);

      cursor: pointer;

      span {
        display: block;
        font-weight: bold;
        color: white;
        font-size: 12px;
      }
    }

    .import-button {
      position: fixed;
      bottom: 30px;
      left: 20px;
      background-color: #4abde1;

      width: fit-content;

      padding: 15px;
      border-radius: 100px;
      box-sizing: border-box;
      border: 3px solid white;

      box-shadow: 0 0 20px rgba(0 0 0 / 20%);

      span {
        display: block;
        font-weight: bold;
        color: white;
        font-size: 12px;
      }
    }
  }
}
