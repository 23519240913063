@keyframes UpDown {
  /* 開始地点 */
  0% {
    /* Y軸0px */
    transform: translateY(-5px);
  }
  /* 終了地点 */
  100% {
    /* Y軸50px */
    transform: translateY(5px);
  }
}

.app-start-screen-wrapper {
  width: 100vw;
  height: 100vh;

  background-color: var(--paper);

  display: flex;
  align-items: center;
  justify-content: center;

  .start-screen-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    margin-bottom: 100px;

    .icon {
      display: block;
      width: 150px;
      height: 150px;

      // updown animation
      /* アニメーションの名前 */
      animation-name: UpDown;
      /* アニメーションの１回分の長さ */
      animation-duration: 1s;
      /* アニメーションの回数 */
      animation-iteration-count: infinite;
      /* アニメーションの進行具合 */
      animation-timing-function: ease-in-out;
      /* アニメーション再生の向き */
      animation-direction: alternate;
    }

    .loading-text {
      display: block;
      text-align: center;
      font-weight: bold;
      font-size: 18px;
      color: var(--ink);
    }
  }
}
