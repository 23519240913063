.slide-block-motion-wrapper {
  position: relative; // for app guide

  display: flex;
  flex-direction: column;
  gap: 10px;

  &.indent-1 {
    margin-left: 2px;
  }

  &.indent-2 {
    margin-left: 5px;
  }

  .slide-block-top-section {
    display: flex;
    align-items: center;

    gap: 15px;

    .slide-block-type-section {
      display: flex;
      align-items: center;
      gap: 5px;

      width: fit-content;

      font-size: 10px;

      .slide-block-type-icon {
        opacity: 50%;
      }

      .slide-block-type-name {
        font-weight: bold;
        opacity: 50%;
      }
    }

    .reorder-section {
      display: flex;
      gap: 10px;

      .down {
        transform: rotate(180deg);
      }

      .left {
        transform: rotate(-90deg);
      }

      .right {
        transform: rotate(90deg);
      }
    }

    .delete-button {
      margin-left: auto;
    }
  }

  .block-content {
    display: flex;
    align-items: center;
    gap: 15px;

    // -moz-user-select: none; /* firefox */
    // -webkit-user-select: none; /* Safari */
    // -ms-user-select: none; /* IE*/
    // user-select: none; /* Standard syntax */

    .indentation-markings {
      display: flex;
      gap: 5px;

      .indent-dot {
        width: 5px;
        height: 5px;
        border-radius: 100px;
        opacity: 50%;
      }
    }

    .block-container {
      width: 100%;

      display: flex;
      flex-direction: column;
      gap: 8px;

      &.shadowed {
        transform: translateX(-3px);
        margin-bottom: 18px;
        margin-top: 25px;
      }

      .block-name {
        margin-left: 10px;
        font-weight: bold;
        font-size: 12px;
      }

      input.block-name {
        width: 50%;
        border: 2px dashed transparent;
        border-radius: 5px;

        padding: 5px 10px;
      }
    }
  }
}
