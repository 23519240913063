textarea {
  transition: height 0.3s ease;
}

.block-inner-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.text-block-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  border-radius: 20px;

  .title {
    display: block;
    font-weight: bold;
    font-size: 18px;
  }

  .text {
    margin: 0;
    font-size: 16px;
    line-height: 26px;
  }

  .edit-buttons {
    margin-left: auto;
    margin-top: 10px;

    display: flex;
    gap: 10px;
    align-items: center;

    .color-change-button {

      width: 30px;
      height: 30px;
      border-radius: 100px;

      box-shadow: 0 0 15px rgba(0 0 0 / 30%);
      border: 2px solid white;
      box-sizing: border-box;

      &.darker {
        background-color: rgba(0 0 0 / 50%);
      }

      &.lighter {
        background-color: rgba(255 255 255 / 50%);
      }

      &.none {
        background-color: transparent;
      }
    }

    .shadow-button {
      width: 25px;
      height: 25px;
      background-color: rgba(0 0 0 / 10%);
      border: 2px solid white;
      border-radius: 3px;
      box-sizing: border-box;

      transition: box-shadow 0.3s ease;

      box-shadow: 3px 3px 0px white, 0 0 10px rgba(0 0 0 / 20%);

      &.shadowed {
        box-shadow: 0 0 10px rgba(0 0 0 / 20%);
      }

    }
  }


}

.text-block-wrapper.darker {
  background-color: rgba(0, 0, 0, 10%);
}

.text-block-wrapper.lighter {
  background-color: rgba(255, 255, 255, 30%);
}

.text-block-wrapper.none {
  background-color: transparent;
}