.test-home-wrapper {
  position: relative;

  background-color: rgb(242, 242, 242);
  width: 100vw;
  height: 100vh;

  padding: 20px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

  .texts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    max-width: 500px;

    color: rgb(59, 59, 71);

    p {
      margin: 0;
      font-size: 16px;
      line-height: 24px;
    }

    .card {
      padding: 20px;
      box-sizing: border-box;
      background-color: white;
      border-radius: 10px;
    }

    .header {
      font-weight: bold;
      font-size: 22px;
      margin-bottom: 20px;
    }

    .description {
      font-weight: bold;
    }

    .memo {}


  }

  .logo-section {
    position: absolute;
    bottom: 30px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    .lekcha-logo {
      width: 80px;
    }

    .manage {
      display: block;
      font-weight: bold;
      font-size: 12px;
      opacity: 10%;
    }
  }

  .sample-buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    max-width: 500px;

    .link {
      width: 100%;
  
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #4abde1;
      padding: 15px;
      box-shadow: 0 0 10px rgba(0 0 0 / 10%);
      border-radius: 150px;
      box-sizing: border-box;
  
      border: 3px solid white;
  
      span {
        font-size: 16px;
        font-weight: bold;
        color: white;
      }
    }
  }

  
}