.profile-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 50px;

  width: 100%;

  .profile-info-section {
    display: flex;
    flex-direction: column;
    gap: 15px;

    padding: 25px 20px;
    border-radius: 20px;

    box-sizing: border-box;

    background-color: rgba(255 255 255 / 70%);
    backdrop-filter: blur(50px);

    box-shadow: 0 0 20px rgba(0 0 0 / 10%);

    width: 100%;

    .image-section {
      display: flex;
      justify-content: center;
      align-items: center;

      .image-wrapper {
        width: 100px;
        height: 100px;
        background-color: white;
        border-radius: 100px;
        border: 5px solid white;
        box-shadow: 0 0 20px rgba(0 0 0 / 15%);

        display: flex;
        justify-content: center;
        align-items: center;

        overflow: hidden;

        .person-icon {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .person-icon-no-image {
          font-size: 52px;
        }
      }
    }

    .text-section {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .profile-header {
        display: block;
        text-align: center;

        font-size: 32px;
        font-weight: bold;
      }

      .handle-name {
        display: block;
        text-align: center;

        font-size: 16px;
        font-weight: bold;

        color: var(--grayFont);
      }

      .profile-description {
        margin: 0;
        margin-top: 20px;

        text-align: center;
        font-size: 14px;
        color: var(--grayFont);
      }
    }
  }

  .profile-control-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .edit-profile-button {
      display: flex;
      align-items: center;
      gap: 10px;

      border: 2px solid var(--ink);
      padding: 10px 15px;
      border-radius: 100px;

      font-size: 16px;

      color: var(--ink);

      .edit-profile-icon {
        display: block;
      }

      .edit-profile-button-text {
        display: block;
        font-weight: bold;
      }
    }

    .profile-link-button {
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      border: 2px solid var(--ink);
      box-shadow: 0 0 20px rgba(0 0 0 / 10%);

      width: 20px;
      height: 20px;

      padding: 10px;

      color: var(--ink);

      .profile-link-icon {
        display: block;
        width: 20px;
        height: 20px;

        &.small {
          width: 150px;
          height: 15px;
        }
      }
    }
  }

  .contents-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
}
