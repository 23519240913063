.authentication-page-wrapper {
  min-height: 100vh;
  width: 100vw;

  // dotted grid background
  background: rgb(248, 248, 248);
  background-image: radial-gradient(rgba(0, 0, 0, 20%) 1px, transparent 0);
  background-size: 20px 20px;
  background-position: -19px -19px;


  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 50px 20px;
  box-sizing: border-box;

  color: rgb(76, 76, 76);

  a,
  .link {
    text-decoration: none;
    cursor: pointer;
    color: #4abde1;
    font-weight: bold;
    margin: 0 3px;
  }

  .authentication-container {
    width: 100%;
    max-width: 400px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 15px;

    .service-logo {
      width: fit-content;
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: center;

      margin-bottom: 10px;

      background-color: white;

      padding: 10px 30px;
      box-sizing: border-box;
      border-radius: 100px;

      // border: 3px solid #4abde1;
      box-shadow: 0 0 20px #4abee16c;




      .icon {
        width: 25px;
      }

      .logo {
        width: 55px;
      }

      span {
        display: block;
        font-weight: bold;
      }

      .lekcha {
        font-size: 22px;
        color: #4abde1;
      }

      .blocks {
        padding: 5px 10px;
        border-radius: 10px;

        background-color: #4abde1;
        color: white;

        box-shadow: 3px 3px 0px #c4e5ef;
        border: 2px solid white;

        font-size: 16px;
      }
    }

    .authentication-card {
      padding: 25px 20px;
      box-sizing: border-box;
      min-width: 300px;
      width: 100%;
      background-color: white;
      border-radius: 20px;
      box-shadow: 0 0 20px rgba(0 0 0 / 10%);

      display: flex;
      flex-direction: column;
      gap: 20px;

      .card-header {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
        justify-content: center;

        span {
          text-align: center;
        }

        .header {
          font-weight: bold;
          font-size: 18px;
        }

        .comment {
          font-size: 14px;
          color: rgb(168, 168, 168);
        }
      }

      .line {
        border-bottom: 1px dashed rgb(228, 228, 228);
        width: 100%;
      }

      .forms-wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .authentication-form-section {
          display: flex;
          flex-direction: column;
          gap: 5px;

          .form-title {
            font-size: 12px;
            font-weight: bold;
          }

          .form {
            width: 100%;
            padding: 10px;
            box-sizing: border-box;
            border-radius: 8px;
            background-color: rgb(246, 246, 246);
            border: 1px solid rgb(232, 232, 232);
          }

          .validationMessage {
            display: flex;
            align-items: center;
            gap: 5px;

            color: red;
            opacity: 50%;
            font-size: 12px;
            font-weight: bold;
          }
        }
      }

      .button-section {
        margin-top: 10px;

        display: flex;
        flex-direction: column;
        gap: 20px;

        .agreement-section {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;

          * {
            cursor: pointer;
          }

          label {
            font-size: 12px;
          }
        }

        .button {
          cursor: pointer;
          width: 100%;
          background-color: #4abde1;
          padding: 12px 10px;
          box-sizing: border-box;
          border-radius: 100px;

          box-shadow: 0 0 15px rgba(74 189 225 / 50%);
          border: 2px solid white;

          display: flex;
          align-items: center;

          transition: background-color .3s ease, border .3s ease, box-shadow .3s ease;

          &.disabled {
            cursor: default;
            background-color: rgb(216, 216, 216);
            box-shadow: none;
            border: none;

            transition: background-color .3s ease, border .3s ease, box-shadow .3s ease;
          }

          span {
            display: block;
            width: 100%;
            text-align: center;

            color: white;
            font-weight: bold;
            font-size: 14px;
          }
        }

        .masterErrorMessage {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;

          color: red;
          opacity: 50%;
          font-size: 12px;
          font-weight: bold;
        }

        .below-button-message {
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            font-size: 14px;
            text-align: center;
          }
        }
      }

      .appendix-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        .appendix {

          span {
            display: block;
            text-align: center;
            font-size: 14px;
          }

          &.modest {
            span, .link {
              font-weight: normal;
            }
          }

        }
      }


    }
  }
}